.offer {
  &__item {
    box-shadow: 2px 0px 15px 5px $color-shadow;
    border-radius: 5px;
    transition: all 0.5s;
    overflow: hidden;
    &:hover {
      .offer__item__image {
        overflow: hidden;
        img {
          transform: scale(1.1);
        }
      }
    }
    &__image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        object-fit: cover;
        height: 100%;
        width: 100%;
        transition: 0.5s;
        @include media-breakpoint-down(lg) {
          border-bottom-left-radius: 0px;
          border-top-right-radius: 5px;
        }
      }
    }
    &__content {
      padding: 30px 30px 30px 10px;
      @include media-breakpoint-down(xxl) {
        padding: 30px 15px 30px 10px;
      }
      @include media-breakpoint-down(xl) {
        padding: 30px 10px 30px 0;
      }
      @include media-breakpoint-down(lg) {
        padding: 15px;
      }
      span {
        &:first-child {
          margin-bottom: 15px;
        }
      }
      h3 {
        font-size: 22px;
        margin-bottom: 12px;
        @include media-breakpoint-down(xxl) {
          font-size: 20px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 18px;
        }
      }
      .price {
        margin-bottom: 0;
        span {
          color: $color-primary;
          font-weight: 500;
        }
      }
    }
  }
}
