.add-book {
  &__input {
    &--image {
      label {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      img {
        width: 250px;
        height: 200px;
        border-radius: 5px;
        object-fit: cover;
        margin: 0 auto;
      }
    }
  }
  input,
  textarea {
    width: 100%;
    border: none;
    padding: 10px 15px;
    background-color: $color-secondary;
    transition: all 0.5s;
    border-radius: 5px;
    border: 2px solid transparent;
    margin-top: 10px;
    &:hover {
      border: 2px solid $color-border;
    }
    &:focus {
      border: 2px solid $color-dark;
      outline: none;
    }
  }
  p {
    padding-top: 8px;
    padding-left: 20px;
    margin-bottom: 0;
    color: $color-danger;
    font-size: 14px;
  }
  label {
    margin-top: 15px;
    cursor: pointer;
  }
}
