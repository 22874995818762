

/*
* Colors
*/
$color-primary: #A79CB1;
$color-secondary: #F8F9FA;
$color-light: #ffffff;
$color-dark: #5A2B3B;
$color-gray: #676768;
$color-border: #D5D5D5;
$color-danger: #fc0000;
$color-shadow: rgba(1, 6, 33, 0.06);

