.cart {
  padding: 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__close {
    position: absolute;
    top: 30px;
    left: 20px;
    cursor: pointer;
    svg {
      color: $color-dark;
      font-size: 24px;
    }
  }
  &__empty {
    text-align: center;
    svg {
      font-size: 150px;
    }
  }
  &__btn-offline {
    width: 100%;
    border: none;
    text-align: center;
    padding: 10px 30px;
    line-height: 30px;
    margin: 0;
    background-color: $color-gray;
    color: $color-light;
    border-radius: 5px;
    cursor: not-allowed;
    &:focus {
      outline: none;
    }
  }
  &__confirm {
    &__price {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    button {
      width: 100%;
      margin-top: 20px;
    }
  }
  &__books {
    height: calc(100vh - 260px);
    &.bs-scroll {
      margin-right: -25px;
    }
    &__image {
      height: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    &__content {
      .title {
        color: $color-dark;
        font-size: 14px;
        margin-bottom: 0;
      }
      .price {
        font-size: 14px;
        margin-top: 5px;
        padding-bottom: 10px;
        display: inline-block;
      }
    }
  }
  &__book {
    margin-bottom: auto;
    height: calc(100vh - 215px);
    &.bs-scroll {
      margin-right: -25px;
    }
    &--image {
      margin-bottom: 15px;
      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    ul {
      li {
        margin-bottom: 8px;
        display: grid;
        grid-template-columns: 120px 1fr;
        @include media-breakpoint-down(sm) {
          font-size: 15px;
          grid-template-columns: 90px 1fr;
        }
        span {
          color: $color-dark;
        }
      }
    }
  }
}
