.subscribe {
  &__wrapper {
    padding: 30px 20px;
    border-radius: 5px;
    box-shadow: 2px 0px 15px 5px $color-shadow;
    @include media-breakpoint-down(md) {
      box-shadow: none;
      padding: 0;
    }
    &--images {
      @include media-breakpoint-down(md) {
        height: 400px;
      }
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
  }
  &__content {
    &--subtitle {
      font-size: 20px;
      margin-bottom: 20px;
    }
    form {
      .input {
        display: flex;
        @include media-breakpoint-down(sm) {
          display: inherit;
        }
        input {
          border: 0;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          box-shadow: 2px 0px 15px 5px $color-shadow;
          padding: 15px 15px;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
        button {
          border-top-right-radius: 5px !important;
          border-bottom-right-radius: 5px !important;
          border-radius: 0;
          @include media-breakpoint-down(sm) {
            width: 100%;
            margin-top: 20px;
            border-radius: 5px !important;
          }
        }
      }
    }
    &--social {
      display: flex;
      gap: 7px;
      margin-top: 23px !important;
      @include media-breakpoint-down(sm) {
        justify-content: center;
      }
      li {
        a {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color-primary;
          transition: all 0.5s;
          border-radius: 50%;
          svg {
            color: $color-light;
            font-size: 18px;
          }
          &:hover {
            background-color: $color-dark;
          }
        }
      }
    }
  }
}
