.book-list {
  box-shadow: 2px 0px 15px 5px $color-shadow;
  border-radius: 5px;
  background-color: $color-light;
  height: 100%;
  padding: 20px;
  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
  &:hover {
    .functional-icons {
      right: 15px;
      li {
        opacity: 1;
      }
    }
  }
  &__left {
    position: relative;
    img {
      border-radius: 5px;
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }
  &__discount {
    position: absolute;
    top: 20px;
    left: 0px;
    padding: 5px 5px;
    border-radius: 0 7px 7px 0;
    color: $color-light;
    background-color: $color-primary;
    font-size: 14px;
    z-index: 9;
    .on-sale {
      position: relative;
      content: "";
      padding: 5px 8px;
      &::before {
        width: 22px;
        height: 22px;
        position: absolute;
        content: "";
        cursor: pointer;
        top: -1px;
        right: -17px;
        margin: 5px;
        border: none;
        border-radius: 1px 1px 4px;
        text-overflow: clip;
        text-shadow: none;
        transform: rotateY(1deg) rotateZ(-45deg);
        background-color: $color-primary;
        z-index: -1;
      }
    }
  }
  .functional-icons {
    position: absolute;
    top: 15px;
    right: 0px;
    left: auto;
    transition: 0.5s;
    button {
      border: none;
      &:focus {
        outline: none;
      }
    }
    li {
      opacity: 0;
      transition: 0.5s;
      &:nth-child(1) {
        transition-delay: 0.2s;
      }
      &:nth-child(2) {
        transition-delay: 0.3s;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .icon {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-primary;
        color: $color-light;
        border-radius: 50%;
        transition: all 0.5s;
        cursor: pointer;
        svg {
          font-size: 18px;
        }
        &:hover {
          background-color: $color-dark;
        }
      }
    }
  }
  &__content {
    &--title {
      font-size: 20px;
      margin-bottom: 8px;
    }
    &--subtitle {
      font-size: 14px;
      margin-bottom: 8px;
    }
    &--author {
      margin-bottom: 8px;
      span {
        font-weight: 500;
        color: $color-dark;
      }
    }
    .price {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
      span {
        color: $color-dark;
      }
    }
    button {
      span {
        @include media-breakpoint-down(sm) {
          svg {
            display: none;
          }
        }
      }
    }
  }
  .calculation__button button, .calculation__button span {
    width: 35px;
    height: 35px;
  }
}
