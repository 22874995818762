.footer {
    background-color: $color-dark;
    color: $color-light;
    &__top {
        display: flex;
        gap: 15px;
        &--icon {
            svg {
                font-size: 45px;
                color: $color-primary;
            }
        }
        h3 {
            color: $color-light;
            font-size: 20px;
            margin-bottom: 5px;
        }
        &--info {
            a {
                color: $color-light;
                &:hover {
                    color: $color-primary;
                }
            }
        }
    }
    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        padding-bottom: 20px;
        @include media-breakpoint-down(md) {
            display: inherit;
            text-align: center;
        }
        div {
            text-align: center;
            h3 {
                font-size: 65px;
                color: $color-light;
                margin: 0;
                a {
                    color: $color-light;
                    &:hover {
                        color: $color-primary;
                    }
                }
                @include media-breakpoint-down(md) {
                    margin-bottom: 10px;
                }
            }
            span {
                font-size: 14px;
                color: $color-light;
                margin: 0;
            }
        }
        div {
            text-align: center;
            img {
                width: 20%;
                margin-bottom: 10px;
            }
            p {
                margin: 0;
                a {
                    color: $color-light;
                    &:hover {
                        color: $color-primary;
                    }
                }
            }
        }

    }
}
