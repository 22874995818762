.filter {
  &__item {
    margin-bottom: 20px;
    text-transform: capitalize;
    &--category {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      button {
        border: none;
        background-color: $color-light;
        text-transform: capitalize;
        transition: all 0.5s;
        padding: 0;
        border-bottom: 1px solid #fff;
        &:focus {
          outline: none;
        }
        &:hover, &.active {
          border-bottom: 1px solid $color-border;
          color: $color-primary;
        }
      }
    }
    .amount {
      margin-bottom: 10px;
    }
    .price {
      width: 100%;
    }
  }
  &--title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  form {
    input, select {
      width: 100%;
      border: none;
      padding: 10px;
      background-color: $color-secondary;
      transition: all 0.5s;
      border-radius: 5px;
      border: 2px solid transparent;
      &:hover {
        border: 2px solid $color-border;
      }
      &:focus {
        border: 2px solid $color-dark;
        outline: none;
      }
    }
    select {
      cursor: pointer;
      text-transform: capitalize;
    }
  }
}
