.hero {
  &:hover {
    .prev {
      left: 40px;
      opacity: 1;
    }
    .next {
      right: 00px;
      opacity: 1;
    }
  }
  &__swiper {
    padding-bottom: 80px;
  }
  .prev,
  .next {
    width: 40px;
    height: 40px;
    background-color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    z-index: 99;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s;
    opacity: 0;
    cursor: pointer;
    svg {
      color: $color-light;
      font-size: 35px;
    }
    &:hover {
      background-color: $color-dark;
    }
  }
  .prev {
    left: 100px;
    right: auto;
  }
  .next {
    right: 40px;
    left: auto;
  }
  &__image2 {
    padding-right: 30px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 30px;
    display: inline-block;
    @include media-breakpoint-down(lg) {
      padding-right: 25px;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 35px;
      z-index: 1;
      border: 3px dotted $color-primary;
      border-radius: 5px;
      height: 100%;
      width: 80%;
    }
    img {
      position: relative;
      z-index: 2;
      border-radius: 5px;
    }
  }
}

.hero-classic {
  height: calc(100vh - 90px);
  min-height: 750px;
  padding: 30;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $color-secondary;
  text-align: center;
  @include media-breakpoint-down(md) {
    height: 580px;
    min-height: auto;
  }
  @include media-breakpoint-down(sm) {
    height: 480px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: $color-dark;
    opacity: 0.7;
    z-index: 1;
    // @include media-breakpoint-down(md) {
    //   display: none;
    // }
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    // @include media-breakpoint-down(md) {
    //   display: none;
    // }
  }
  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 720px;
    color: $color-light;
    h1 {
      color: $color-light;
    }
    input {
      width: 100%;
      border: none;
      padding: 10px 10px;
      background-color: $color-secondary;
      transition: all 0.5s;
      border-radius: 5px;
      border: 2px solid transparent;
      &:hover {
        border: 2px solid $color-border;
      }
      &:focus {
        border: 2px solid $color-primary;
        outline: none;
      }
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
  }
}

.hero-modern {
  padding: 30px 35px;
  &__card {
    background-color: $color-secondary;
    border-radius: 5px;
    padding: 20px;
    p {
      font-size: 14px;
    }
    h5 {
      @include media-breakpoint-between(sm, md) {
        font-size: 18px;
      }
    }
    a {
      padding: 5px 10px;
      font-size: 14px;
    }
  }
  .order-process {
    border: 1px solid $color-border;
    padding: 25px 0;
    &__item {
      display: grid;
      align-items: center;
      justify-items: center;
      grid-template-columns: 100px 1fr;
      span {
        border-radius: 50%;
        background-color: $color-primary;
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 600;
        color: $color-secondary;
      }
      p {
        margin-bottom: 0;
        font-size: 15px;
      }
    }
  }
  .hero__swiper {
    padding-bottom: 40px;
    .swiper-button-prev,
    .swiper-button-next {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-dark;
      color: $color-light;
      border-radius: 50%;
      cursor: pointer;
      &::after {
        font-size: 15px;
        font-weight: 600;
      }
    }
    .swiper-button-next {
      &::after {
        margin-left: 3px;
      }
    }
    .swiper-button-prev {
      &::after {
        margin-right: 3px;
      }
    }
  }
}
